@charset "UTF-8";
html {
  min-height: 100%;
  min-width: 100%;
  font-size: 62.5%; }

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
  min-height: 100%;
  min-width: 100%;
  background: #ffffff; }

*::selection {
  background: #000000;
  color: #ffffff; }

/*************************reset-erd*************************/
h1, h2, h3, h4, h5, h6, th, p, label, figure {
  font-weight: normal;
  margin: 0; }

a {
  text-decoration: none !important; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

input, select, textarea, button, a {
  outline: none !important;
  border: none; }

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0; }

input, select, textarea {
  background: #ffffff;
  border-radius: 0; }

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type=text]::-ms-clear {
  display: none; }

i {
  display: inline-block;
  vertical-align: middle; }

nav {
  background: none;
  border: none;
  margin: 0;
  min-height: auto !important; }

nav button.navbar-toggle {
  background: none !important;
  border: none !important; }

nav.navbar-expand-lg .navbar-nav a.nav-link {
  padding: 0;
  margin: 0; }

.navbar-collapse {
  border: none !important;
  box-shadow: 0 0 0 0 !important;
  padding: 0; }

.navbar-brand {
  padding: 0;
  height: auto; }

.dropdown button {
  box-shadow: 0 0 0 0 !important;
  border: none;
  padding: 0; }

/*************************reset-erd*************************/
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-instagram:before {
  content: "\e900"; }

.icon-direction:before {
  content: "\e901"; }

.icon-facebook:before {
  content: "\e902"; }

.icon-favicon .path1:before {
  content: "\e903";
  color: #286c9d; }

.icon-favicon .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: #0c8fa9; }

.icon-location:before {
  content: "\e905"; }

.icon-message:before {
  content: "\e906"; }

.icon-next:before {
  content: "\e907"; }

.icon-phone:before {
  content: "\e908"; }

.icon-send:before {
  content: "\e909"; }

.icon-twitter:before {
  content: "\e90a"; }

@font-face {
  font-family: 'Conv_Poppins-Bold';
  src: url("../fonts/Poppins-Bold.eot");
  src: local("☺"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype"), url("../fonts/Poppins-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Light';
  src: url("../fonts/Poppins-Light.eot");
  src: local("☺"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Medium';
  src: url("../fonts/Poppins-Medium.eot");
  src: local("☺"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype"), url("../fonts/Poppins-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-Regular';
  src: url("../fonts/Poppins-Regular.eot");
  src: local("☺"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Poppins-SemiBold';
  src: url("../fonts/Poppins-SemiBold.eot");
  src: local("☺"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/Poppins-SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_Blanka-Regular';
  src: url("../fonts/Blanka-Regular.eot");
  src: local("☺"), url("../fonts/Blanka-Regular.woff") format("woff"), url("../fonts/Blanka-Regular.ttf") format("truetype"), url("../fonts/Blanka-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?c2hgqw");
  src: url("../fonts/icomoon.eot?c2hgqw#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?c2hgqw") format("truetype"), url("../fonts/icomoon.woff?c2hgqw") format("woff"), url("../fonts/icomoon.svg?c2hgqw#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.bg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important; }

header {
  padding: 51px 0 50px 0; }
  @media (max-width: 991px) {
    header {
      padding: 30px 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: #fff;
      z-index: 99;
      box-shadow: 0 0 8px #4c86ae; } }
  header.home-header {
    padding: 74px 40px 0 40px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99; }
    @media (max-width: 1700px) {
      header.home-header {
        padding: 50px 15px 0 15px; } }
    @media (max-width: 991px) {
      header.home-header {
        padding: 20px 0;
        background: #fff;
        position: fixed;
        z-index: 9999; } }
    header.home-header nav a.navbar-brand {
      margin: 0; }
      header.home-header nav a.navbar-brand img {
        max-width: 140px !important;
        width: 100%;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
        @media (max-width: 991px) {
          header.home-header nav a.navbar-brand img {
            max-width: 100px !important;
            position: absolute;
            z-index: 99; } }
    @media (max-width: 991px) {
      header.home-header nav.menuShow a.navbar-brand img {
        max-width: 40px !important; } }
    header.home-header .navbar-nav {
      background: #fff; }
  header nav {
    padding: 0 !important;
    align-items: flex-start !important; }
    header nav a.navbar-brand {
      margin-top: 10px; }
      @media (max-width: 991px) {
        header nav a.navbar-brand {
          margin-top: 0; } }
      header nav a.navbar-brand img {
        max-width: 191px; }
        @media (max-width: 991px) {
          header nav a.navbar-brand img {
            max-width: 130px; } }
    header nav .mobile-speed-menu {
      display: none; }
      @media (max-width: 991px) {
        header nav .mobile-speed-menu {
          display: flex;
          flex-direction: row; } }
      header nav .mobile-speed-menu li:not(:last-child) {
        padding: 0 7px; }
      header nav .mobile-speed-menu li:last-child {
        padding-left: 7px; }
      header nav .mobile-speed-menu li.location a {
        background: #0b8fa9; }
      header nav .mobile-speed-menu li.phone a {
        background: #4c86ae; }
      header nav .mobile-speed-menu li a {
        display: block;
        padding: 10px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px; }
        header nav .mobile-speed-menu li a i {
          font-size: 18px; }
          header nav .mobile-speed-menu li a i:before {
            color: #fff; }
    header nav button.navbar-toggler {
      padding: 0;
      margin: 0;
      height: 32px;
      width: 35px; }
      header nav button.navbar-toggler span.navbar-toggler-icon {
        width: 35px;
        height: 2.5px;
        border-radius: 2.5px;
        background: #4c86ae;
        position: relative; }
        header nav button.navbar-toggler span.navbar-toggler-icon:before, header nav button.navbar-toggler span.navbar-toggler-icon:after {
          content: "";
          width: 35px;
          height: 2.5px;
          border-radius: 2.5px;
          background: #4c86ae;
          position: absolute;
          display: inline-block;
          left: 0; }
        header nav button.navbar-toggler span.navbar-toggler-icon:before {
          top: -10px; }
        header nav button.navbar-toggler span.navbar-toggler-icon:after {
          bottom: -10px; }
    header nav .collapse {
      justify-content: flex-end; }
    header nav .navbar-nav {
      padding: 27px 31px; }
      @media (max-width: 991px) {
        header nav .navbar-nav {
          padding: 30px 0 0 0; } }
      header nav .navbar-nav li {
        padding: 0 12px; }
        @media (max-width: 991px) {
          header nav .navbar-nav li {
            padding: 0; } }
        header nav .navbar-nav li a {
          font-family: "Conv_Poppins-Medium";
          font-size: 1.5rem;
          line-height: 2.1rem;
          color: #0b8fa9;
          position: relative; }
          @media (max-width: 991px) {
            header nav .navbar-nav li a {
              padding: 5px 0 !important; } }
          header nav .navbar-nav li a:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -2px;
            height: 2px;
            background: #0b8fa9;
            width: 0;
            -webkit-transition: .4s ease-in-out;
            -moz-transition: .4s ease-in-out;
            -ms-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
            transition: .4s ease-in-out; }
            @media (max-width: 991px) {
              header nav .navbar-nav li a:before {
                bottom: 3px; } }
          header nav .navbar-nav li a:hover:before {
            width: 40%; }
            @media (max-width: 991px) {
              header nav .navbar-nav li a:hover:before {
                width: 10%; } }
        header nav .navbar-nav li.active a:before {
          width: 40%; }
          @media (max-width: 991px) {
            header nav .navbar-nav li.active a:before {
              width: 10%; } }
    header nav .nav-icons {
      display: flex;
      flex-direction: row;
      padding-left: 13px; }
      @media (max-width: 991px) {
        header nav .nav-icons {
          display: none; } }
      header nav .nav-icons li:not(:last-child) {
        padding-right: 6px; }
      header nav .nav-icons li.location a {
        background: #0b8fa9; }
      header nav .nav-icons li.phone a {
        background: #4c86ae; }
      header nav .nav-icons li a {
        display: block;
        padding: 10px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75px; }
        header nav .nav-icons li a i {
          font-size: 33px; }
          header nav .nav-icons li a i:before {
            color: #fff; }

footer {
  background: #333333;
  position: relative; }
  @media (max-width: 991px) {
    footer {
      padding-bottom: 25px; } }
  footer:before {
    content: "";
    position: absolute;
    right: 0;
    width: 40%;
    background: #0b8fa9;
    height: 100%; }
    @media (max-width: 991px) {
      footer:before {
        width: 100%;
        height: 80px;
        bottom: 0; } }
  footer .row {
    display: flex;
    align-items: center; }
  footer .footer__logo {
    padding: 27px 0; }
    @media (max-width: 991px) {
      footer .footer__logo {
        text-align: center;
        padding: 25px 0 15px 0; } }
    footer .footer__logo img {
      max-width: 109px;
      width: 100%; }
  footer .footer__menu {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    footer .footer__menu ul {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991px) {
        footer .footer__menu ul {
          flex-direction: column; } }
      footer .footer__menu ul li {
        padding: 0 15px; }
        @media (max-width: 1200px) {
          footer .footer__menu ul li {
            padding: 0 6px; } }
        @media (max-width: 991px) {
          footer .footer__menu ul li {
            padding: 5px; } }
        footer .footer__menu ul li a {
          font-family: "Conv_Poppins-Regular";
          font-size: 1.4rem;
          line-height: 2.1rem;
          color: #fff; }
  footer .footer__follow {
    padding: 27px 0;
    background: #434343; }
    @media (max-width: 991px) {
      footer .footer__follow {
        background: none;
        padding: 10px 0 30px 0; } }
    footer .footer__follow ul {
      display: flex;
      align-items: center;
      justify-content: center; }
      footer .footer__follow ul li {
        padding: 0 3.5px; }
        footer .footer__follow ul li a i {
          font-size: 26px; }
          footer .footer__follow ul li a i:before {
            color: #fff; }
        footer .footer__follow ul li p {
          font-family: "Conv_Poppins-Light";
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: #fff; }
          footer .footer__follow ul li p span {
            font-family: "Conv_Poppins-Bold";
            display: block; }
  footer .footer__gigant {
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 991px) {
      footer .footer__gigant {
        justify-content: center; } }
    footer .footer__gigant a i {
      width: 90px;
      height: 37px;
      background: url(../images/svg/gigant-logo-white.svg) no-repeat center;
      background-size: cover;
      display: inline-block; }

body {
  background: url(../images/content/bg.jpg) no-repeat center fixed; }

.main-slider {
  position: relative; }
  .main-slider .container-fluid {
    padding: 0; }
    .main-slider .container-fluid > .row {
      margin: 0; }
      .main-slider .container-fluid > .row > div {
        padding: 0;
        position: initial; }
  .main-slider .carousel-indicators {
    display: none; }
    @media (max-width: 991px) {
      .main-slider .carousel-indicators {
        position: absolute;
        right: 15px;
        top: 30%;
        height: auto;
        margin: 0;
        width: auto;
        justify-content: flex-start;
        display: table;
        left: auto;
        transform: translate(0, -50%); } }
    .main-slider .carousel-indicators li {
      background-color: #0b8fa9;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent; }
  .main-slider__top {
    position: relative; }
  .main-slider__title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    padding: 0 30px 0 30px;
    background: #fff; }
    @media (max-width: 991px) {
      .main-slider__title {
        height: auto !important;
        padding: 30px 0;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 2;
        background: rgba(255, 255, 255, 0.7); } }
    @media (max-height: 850px) {
      .main-slider__title {
        min-height: 591px; } }
    @media (max-width: 991px) {
      .main-slider__title {
        min-height: auto; } }
    .main-slider__title > div {
      max-width: 360px;
      margin-bottom: 130px; }
      @media (max-height: 950px) {
        .main-slider__title > div {
          margin-bottom: 60px; } }
      @media (max-width: 991px) {
        .main-slider__title > div {
          margin: 0; } }
    .main-slider__title h1 {
      font-family: "Conv_Poppins-SemiBold";
      font-size: 3.2rem;
      line-height: 3.9rem;
      color: #0b8fa9;
      margin-bottom: 20px; }
      @media (max-width: 1400px) {
        .main-slider__title h1 {
          font-size: 2.8rem;
          line-height: 3.5rem; } }
      @media (max-width: 1276px) {
        .main-slider__title h1 {
          font-size: 2rem;
          line-height: 2.8rem; } }
    .main-slider__title p {
      font-family: "Conv_Poppins-Regular";
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #0b8fa9;
      letter-spacing: 1.2px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden; }
    .main-slider__title a.slide-button {
      font-family: "Conv_Poppins-Medium";
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: #0b8fa9;
      display: inline-block;
      padding: 13px 21px;
      border: 1px solid #0b8fa9;
      border-radius: 23.5px;
      margin-top: 45px; }
  .main-slider__figure {
    width: 100%;
    background-color: #0b8fa9;
    background-blend-mode: screen; }
    @media (max-height: 850px) {
      .main-slider__figure {
        min-height: 850px; } }
    @media (max-width: 991px) {
      .main-slider__figure {
        min-height: auto; } }
  .main-slider__bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2; }
    @media (max-width: 991px) {
      .main-slider__bottom {
        position: initial; } }
    .main-slider__bottom .col-erd-3 {
      flex: 0 0 20%;
      max-width: 20%; }
      @media (max-width: 991px) {
        .main-slider__bottom .col-erd-3 {
          max-width: 100%;
          flex: 0 0 100%; } }
    .main-slider__bottom .col-erd-1 {
      flex: 0 0 13.3333%;
      max-width: 13.3333%; }
    .main-slider__bottom .main-banner {
      height: 100%;
      width: 100%;
      position: relative; }
      .main-slider__bottom .main-banner:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(40, 108, 157, 0.6); }
    .main-slider__bottom .main-about-us {
      padding: 32px 340px 34px 60px;
      position: relative;
      background: #fff; }
      @media (max-width: 1700px) {
        .main-slider__bottom .main-about-us {
          padding-left: 30px;
          padding-right: 300px; } }
      @media (max-width: 1276px) {
        .main-slider__bottom .main-about-us {
          padding-right: 230px; } }
      @media (max-width: 991px) {
        .main-slider__bottom .main-about-us {
          padding: 0; } }
      .main-slider__bottom .main-about-us__text {
        max-width: 330px; }
        @media (max-width: 991px) {
          .main-slider__bottom .main-about-us__text {
            max-width: 100%;
            padding: 30px 15px; } }
      .main-slider__bottom .main-about-us h2 {
        font-family: "Conv_Poppins-Medium";
        font-size: 3.5rem;
        line-height: 4.2rem;
        color: #0b8fa9;
        margin-bottom: 10px; }
        @media (max-width: 1276px) {
          .main-slider__bottom .main-about-us h2 {
            font-size: 2.8rem;
            line-height: 3.5rem; } }
      .main-slider__bottom .main-about-us p {
        font-family: "Conv_Poppins-Regular";
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #0b8fa9;
        letter-spacing: 1.2px;
        margin-bottom: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden; }
        @media (max-width: 1700px) {
          .main-slider__bottom .main-about-us p {
            -webkit-line-clamp: 3; } }
      .main-slider__bottom .main-about-us a {
        font-family: "Conv_Poppins-Medium";
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: #0b8fa9;
        display: inline-block;
        position: relative;
        padding-left: 80px; }
        .main-slider__bottom .main-about-us a:before {
          content: "";
          position: absolute;
          left: 0;
          height: 2px;
          background: #0b8fa9;
          width: 70px;
          top: 50%;
          margin-top: -1px; }
      .main-slider__bottom .main-about-us__banner {
        background: #0b8fa9;
        width: 300px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0; }
        @media (max-width: 1700px) {
          .main-slider__bottom .main-about-us__banner {
            width: 270px; } }
        @media (max-width: 1276px) {
          .main-slider__bottom .main-about-us__banner {
            width: 200px; } }
        @media (max-width: 991px) {
          .main-slider__bottom .main-about-us__banner {
            width: 100%;
            max-width: 100%;
            position: relative;
            height: 250px; } }
        .main-slider__bottom .main-about-us__banner figure {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #4c86ae;
          mix-blend-mode: luminosity; }
    .main-slider__bottom .main-follow {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(68, 169, 183, 0.2);
      border-top: 1px solid #fff; }
      @media (max-width: 991px) {
        .main-slider__bottom .main-follow {
          background: #4c86ae;
          padding: 40px 15px; } }
      .main-slider__bottom .main-follow p {
        font-family: "Conv_Poppins-Regular";
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #fff;
        text-align: center;
        margin-bottom: 10px; }
        .main-slider__bottom .main-follow p span {
          display: block;
          font-family: "Conv_Poppins-Bold"; }
      .main-slider__bottom .main-follow ul {
        display: flex;
        align-items: center;
        justify-content: center; }
        .main-slider__bottom .main-follow ul li {
          padding: 0 5px; }
          .main-slider__bottom .main-follow ul li a i {
            font-size: 23px; }
            .main-slider__bottom .main-follow ul li a i:before {
              color: #fff;
              -webkit-transition: .4s ease-in-out;
              -moz-transition: .4s ease-in-out;
              -ms-transition: .4s ease-in-out;
              -o-transition: .4s ease-in-out;
              transition: .4s ease-in-out; }
          .main-slider__bottom .main-follow ul li a:hover i:before {
            color: #4c86ae; }
    .main-slider__bottom .main-slide-control {
      width: 100%;
      height: 100%;
      background: #fff; }
      @media (max-width: 991px) {
        .main-slider__bottom .main-slide-control {
          display: none; } }
      .main-slider__bottom .main-slide-control > div {
        max-width: 60px;
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto; }
      .main-slider__bottom .main-slide-control a {
        opacity: 1;
        width: auto;
        height: auto; }
        .main-slider__bottom .main-slide-control a.carousel-control-prev {
          left: 0; }
          .main-slider__bottom .main-slide-control a.carousel-control-prev i {
            transform: rotate(180deg); }
        .main-slider__bottom .main-slide-control a.carousel-control-next {
          right: 0; }
        .main-slider__bottom .main-slide-control a i {
          font-size: 40px; }
          .main-slider__bottom .main-slide-control a i:before {
            color: #0b8fa9; }

@media (max-width: 991px) {
  .wrapper {
    margin-top: 99px; } }

.wrapper .common-banner {
  height: 243px;
  width: 100%;
  background-color: #0b8fa9;
  background-blend-mode: screen;
  display: flex;
  align-items: center; }
  .wrapper .common-banner h1 {
    font-family: "Conv_Blanka-Regular";
    font-size: 11rem;
    line-height: 10rem;
    -webkit-text-stroke: 1px #fff;
    color: transparent;
    text-shadow: 0 2px 18px #0b8fa9; }
    .wrapper .common-banner h1 span {
      display: block;
      font-size: 40px;
      line-height: 0.7; }

.wrapper .content {
  padding: 63px 0 60px 0; }
  @media (max-width: 991px) {
    .wrapper .content {
      padding: 40px 0; } }
  .wrapper .content h2 {
    font-family: "Conv_Poppins-SemiBold";
    font-size: 3rem;
    line-height: 3.8rem;
    color: #0b8fa9;
    margin-bottom: 35px;
    padding-left: 45px;
    position: relative; }
    @media (max-width: 991px) {
      .wrapper .content h2 {
        font-size: 2.2rem;
        line-height: 3rem; } }
    .wrapper .content h2:before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      background: url(../images/svg/favicon.svg) no-repeat center;
      background-size: cover;
      width: 28px;
      height: 28px;
      display: inline-block; }
  .wrapper .content p {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: #2e2e2e; }
  .wrapper .content .services_list_wrap {
    border: 1px solid #e3e3e3;
    background: #fff;
    margin-bottom: 40px; }
    .wrapper .content .services_list_wrap figure {
      height: 200px;
      width: 100%;
      opacity: 0.8;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
    .wrapper .content .services_list_wrap__text {
      background: #fff;
      padding: 28px;
      display: flex;
      justify-content: space-between;
      padding-right: 100px;
      position: relative;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
      @media (max-width: 991px) {
        .wrapper .content .services_list_wrap__text {
          padding: 28px 70px 28px 15px; } }
      .wrapper .content .services_list_wrap__text h3 {
        font-family: "Conv_Poppins-SemiBold";
        font-size: 1.55rem;
        line-height: 2.2rem;
        color: #0b8fa9;
        letter-spacing: 0.48px;
        min-height: 50px;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .wrapper .content .services_list_wrap__text span {
        width: 49px;
        height: 49px;
        background: #24b5d2;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: absolute;
        right: 28px; }
        @media (max-width: 991px) {
          .wrapper .content .services_list_wrap__text span {
            right: 15px; } }
      .wrapper .content .services_list_wrap__text i {
        font-size: 23px; }
        .wrapper .content .services_list_wrap__text i:before {
          color: #fff; }
    .wrapper .content .services_list_wrap a:hover figure {
      opacity: 1; }
    .wrapper .content .services_list_wrap a:hover .services_list_wrap__text {
      background: #0b8fa9; }
      .wrapper .content .services_list_wrap a:hover .services_list_wrap__text h3 {
        color: #fff; }
  .wrapper .content .detail__structure .carousel ol {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    margin: 0; }
    .wrapper .content .detail__structure .carousel ol li {
      background-color: #fff;
      height: 5px; }
  .wrapper .content .detail__structure figure {
    height: 400px;
    width: 100%;
    position: relative;
    margin-bottom: 30px; }
    .wrapper .content .detail__structure figure:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(68, 169, 183, 0.2); }
  .wrapper .content .activites_list_wrap {
    position: relative;
    margin-bottom: 40px; }
    .wrapper .content .activites_list_wrap figure {
      height: 450px;
      width: 100%;
      opacity: 0.8;
      position: relative;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
      @media (max-width: 767px) {
        .wrapper .content .activites_list_wrap figure {
          height: 380px; } }
      .wrapper .content .activites_list_wrap figure:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(68, 169, 183, 0.2); }
    .wrapper .content .activites_list_wrap__text {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 15px 15px 30px 15px; }
      .wrapper .content .activites_list_wrap__text h3 {
        font-family: "Conv_Poppins-Light";
        font-size: 2.4rem;
        line-height: 3.2rem;
        color: #fff;
        position: relative;
        letter-spacing: 0.4px; }
        @media (max-width: 767px) {
          .wrapper .content .activites_list_wrap__text h3 {
            font-size: 2rem;
            line-height: 2.6rem; } }
        .wrapper .content .activites_list_wrap__text h3:before {
          content: "";
          position: absolute;
          left: 0px;
          bottom: -4px;
          width: 30%;
          height: 2px;
          background: #fff;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
    .wrapper .content .activites_list_wrap > a:hover figure {
      opacity: 1; }
    .wrapper .content .activites_list_wrap > a:hover .activites_list_wrap__text h3:before {
      width: 40%; }
  @media (max-width: 991px) {
    .wrapper .content .contact__information {
      margin-bottom: 40px; } }
  .wrapper .content .contact__information address {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.6rem;
    line-height: 2.7rem;
    color: #434343;
    max-width: 375px;
    margin-bottom: 30px; }
  .wrapper .content .contact__information ul li:not(:last-child) {
    margin-bottom: 10px; }
  .wrapper .content .contact__information ul li a {
    font-family: "Conv_Poppins-Regular";
    font-size: 1.7rem;
    line-height: 2.7rem;
    color: #434343;
    display: block;
    padding-left: 37px;
    position: relative; }
    .wrapper .content .contact__information ul li a i {
      font-size: 22px;
      position: absolute;
      left: 0; }
      .wrapper .content .contact__information ul li a i:before {
        color: #0b8fa9; }
  .wrapper .content .contact__form .input_wrap {
    margin-bottom: 34px; }
    .wrapper .content .contact__form .input_wrap input {
      width: 100%;
      font-family: "Conv_Poppins-Light";
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #434343;
      padding: 7px 0;
      border-bottom: 1px solid #434343;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
      .wrapper .content .contact__form .input_wrap input::-webkit-input-placeholder {
        font-family: "Conv_Poppins-Light";
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #434343;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .wrapper .content .contact__form .input_wrap input:-moz-placeholder {
        font-family: "Conv_Poppins-Light";
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #434343;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .wrapper .content .contact__form .input_wrap input::-moz-placeholder {
        font-family: "Conv_Poppins-Light";
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #434343;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .wrapper .content .contact__form .input_wrap input:-ms-input-placeholder {
        font-family: "Conv_Poppins-Light";
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #434343;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .wrapper .content .contact__form .input_wrap input:focus {
        border-color: #0b8fa9; }
        .wrapper .content .contact__form .input_wrap input:focus::-webkit-input-placeholder {
          color: #0b8fa9; }
        .wrapper .content .contact__form .input_wrap input:focus:-moz-placeholder {
          color: #0b8fa9; }
        .wrapper .content .contact__form .input_wrap input:focus::-moz-placeholder {
          color: #0b8fa9; }
        .wrapper .content .contact__form .input_wrap input:focus:-ms-input-placeholder {
          color: #0b8fa9; }
  .wrapper .content .contact__form .button_wrap {
    display: flex;
    justify-content: flex-end; }
    .wrapper .content .contact__form .button_wrap button {
      font-family: "Conv_Poppins-Medium";
      font-size: 2rem;
      line-height: 2.6rem;
      color: #fff;
      background: #0b8fa9;
      padding: 18px 15px;
      border-radius: 6px;
      min-width: 200px;
      text-align: center; }
      .wrapper .content .contact__form .button_wrap button i {
        margin-right: 8px;
        font-size: 26px; }
        .wrapper .content .contact__form .button_wrap button i:before {
          color: #fff; }

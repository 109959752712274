@import 'config/all';
@import 'common/all';


body{
	background: url(#{$images}/content/bg.jpg) no-repeat center fixed;
}

.main-slider{
	position: relative;
	.container-fluid{
		padding: 0;
		> .row{
			margin:0;
			> div{
				padding: 0;
				position: initial;
			}
		}
	}
	.carousel-indicators{
		display: none;
		@media (max-width: $md-width){
		    position: absolute;
		    right: 15px;
		    top: 30%;
		    height: auto;
		    margin: 0;
		    width: auto;
		    justify-content: flex-start;
		    display: table;
		    left: auto;
		    transform: translate(0,-50%);
		}
		li{
			background-color: $green;
			border-top:5px solid transparent;
			border-bottom:5px solid transparent;
		}
	}
	&__top{
		position: relative;
	}
	&__title{
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 100%;
		padding: 0 30px 0 30px;
		background: $white;
		@media (max-width: $md-width){
			height: auto !important;
			padding:30px 0;
			position: absolute;
		    left: 0;
		    bottom: 0;
		    width: 100%;
		    z-index: 2;
		    background:rgba(255,255,255,0.7);
		}
		@media (max-height: 850px){
			min-height:591px;
		}
		@media (max-width: $md-width){
			min-height:auto;
		}
		> div{
			max-width: 360px;
			margin-bottom: 130px;
			@media (max-height: 950px){
				margin-bottom:60px;
			}
			@media (max-width: $md-width){
				margin:0;
			}
		}
		h1{
			font-family: $poppinssemibold;
			@include font-size(32,39);
			color: $green;
			margin-bottom: 20px;
			@media (max-width: $max-xl-width){
				@include font-size(28,35);
			}
			@media (max-width: $max-lg-width){
				@include font-size(20,28);
			}
		}
		p{
			font-family: $poppinsregular;
			@include font-size(16,24);
			color: $green;
			letter-spacing: 1.2px;
			display: -webkit-box;
		    -webkit-box-orient: vertical;
		    -webkit-line-clamp: 3;
		    overflow: hidden;
		}
		a{
			&.slide-button{
				font-family: $poppinsmedium;
				@include font-size(14,21);
				color: $green;
				display: inline-block;
				padding:13px 21px;
				border:1px solid $green;
				border-radius: 23.5px;
				margin-top: 45px;
			}
		}
	}
	&__figure{
		width: 100%;
		background-color: $green;
		background-blend-mode: screen;
		@media (max-height: 850px){
			min-height: 850px;
		}
		@media (max-width: $md-width){
			min-height:auto;
		}
	}
	&__bottom{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 2;
		@media (max-width: $md-width){
			position: initial;
		}
		.col-erd-3{
			flex: 0 0 20%;
    		max-width: 20%;
    		@media (max-width: $md-width){
    			max-width: 100%;
			    flex: 0 0 100%;
    		}
		}
		.col-erd-1{
			flex: 0 0 13.3333%;
    		max-width: 13.3333%;	
		}
		.main-banner{
			height: 100%;
			width: 100%;
			position: relative;	
			&:before{
				content:"";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: $blue_rgba;
			}
		}
		.main-about-us{
			padding:32px 340px 34px 60px;
			position: relative;
			background: $white;
			@media (max-width: $duble-width){
		    	padding-left: 30px;
		    	padding-right: 300px;
		    }
		    @media (max-width: $max-lg-width){
		    	padding-right:230px;
		    }
		    @media (max-width: $md-width){
		    	padding:0;
		    }
			&__text{
				max-width: 330px;
				@media (max-width: $md-width){
					max-width: 100%;
    				padding: 30px 15px;
				}
			}
			h2{
				font-family: $poppinsmedium;
				@include font-size(35,42);
				color: $green;
				margin-bottom: 10px;
				@media (max-width: $max-lg-width){
					@include font-size(28,35);
				}
			}
			p{
				font-family: $poppinsregular;
				@include font-size(16,24);
				color: $green;
				letter-spacing: 1.2px;
				margin-bottom: 24px;
			    display: -webkit-box;
			    -webkit-box-orient: vertical;
			    -webkit-line-clamp: 5;
			    overflow: hidden;
			    @media (max-width: $duble-width){
			    	-webkit-line-clamp: 3;
			    }
			}
			a{
				font-family: $poppinsmedium;
				@include font-size(14,21);
				color: $green;
				display: inline-block;
				position: relative;
				padding-left: 80px;
				&:before{
					content:"";
					position: absolute;
					left: 0;
					height: 2px;
					background: $green;
					width: 70px;
					top: 50%;
					margin-top: -1px;
				}
			}
			&__banner{
				background: $green;
				width: 300px;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				@media (max-width: $duble-width){
			    	width: 270px;
			    }
			    @media (max-width: $max-lg-width){
			    	width:200px;
			    }
			    @media (max-width: $md-width){
			    	width:100%;
			    	max-width:100%;
			    	position:relative;
			    	height:250px;
			    }
				figure{
					height: 100%;
					width: 100%;
					position: absolute;
					top: 0;
					right: 0;
					background-color: $blue;
					mix-blend-mode: luminosity;
				}
			}
		}
		.main-follow{
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $green_rgba;
			border-top: 1px solid $white;
			@media (max-width: $md-width){
				background: $blue;
				padding: 40px 15px;
			}
			p{
				font-family: $poppinsregular;
				@include font-size(16,24);
				color: $white;
				text-align: center;
				margin-bottom: 10px;
				span{
					display: block;
					font-family: $poppinsbold;
				}
			}
			ul{
				display: flex;
				align-items: center;
				justify-content: center;
				li{
					padding:0 5px;
					a{
						i{
							font-size: 23px;
							&:before{
								color:$white;
								@include transition;
							}
						}
						&:hover{
							i{
								&:before{
									color:$blue;
								}
							}
						}
					}
				}
			}
		}
		.main-slide-control{
			width: 100%;
			height: 100%;
			background:$white;
			@media (max-width: $md-width){
				display: none;
			}
			> div{
				max-width: 60px;
				position: relative;
				width: 100%;
				height: 100%;
				margin:0 auto;
			}
			a{
				opacity: 1;
				width: auto;
				height: auto;
				&.carousel-control-prev{
					left: 0;
					i{
						transform: rotate(180deg);
					}
				}
				&.carousel-control-next{
					right: 0;
				}
				i{
					font-size: 40px;
					&:before{
						color:$green;
					}
				}
			}
		}
	}
}

.wrapper{
	@media (max-width: $md-width){
		margin-top: 99px;
	}
	.common-banner{
		height: 243px;
		width: 100%;
		background-color: $green;
		background-blend-mode: screen;
		display: flex;
		align-items: center;
		h1{
			font-family: $blankaregular;
			@include font-size(110,100);
			 -webkit-text-stroke: 1px $white;
			 color: transparent;
			 text-shadow: 0 2px 18px $green;
			 span{
			 	display: block;
			 	font-size: 40px;
			 	line-height: 0.7;
			 }
		}
	}
	.content{
		padding:63px 0 60px 0;
		@media (max-width: $md-width){
			padding:40px 0;
		}
		h2{
			font-family: $poppinssemibold;
			@include font-size(30,38);
			color:$green;
			margin-bottom: 35px;
			padding-left: 45px;
			position: relative;
			@media (max-width: $md-width){
				@include font-size(22,30);
			}
			&:before{
				content:"";
				position: absolute;
				left: 0;
				top: 2px;
				background: url(#{$images}/svg/favicon.svg) no-repeat center;
				background-size: cover;
				width: 28px;
				height: 28px;
				display: inline-block;
			}
		}
		p{
			font-family: $poppinsregular;
			@include font-size(15,23);
			color: $fontgrey;
		}
		.services_list_wrap{
			border:1px solid #e3e3e3;
			background: $white;
			margin-bottom: 40px;
			figure{
				height: 200px;
				width: 100%;
				opacity: 0.8;
				@include transition;
			}
			&__text{
				background: $white;
				padding:28px;
				display: flex;
				justify-content: space-between;
				padding-right: 100px;
				position: relative;
				@include transition;
				@media (max-width: $md-width){
					padding:28px 70px 28px 15px;
				}
				h3{
					font-family: $poppinssemibold;
					@include font-size(15.5,22);
					color: $green;
					letter-spacing: 0.48px;
					min-height: 50px;
					@include transition;
				}
				span{
					width: 49px;
					height: 49px;
					background: $lightgreen;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					position: absolute;
					right: 28px;
					@media (max-width: $md-width){
						right: 15px;
					}
				}
				i{
					font-size: 23px;
					&:before{
						color:$white;
					}
				}
			}
			a{
				&:hover{
					figure{
						opacity:1;
					}
					.services_list_wrap__text{
						background: $green;
						h3{
							color: $white;
						}
					}
				}
			}
		}
		.detail__structure{
			.carousel{
				ol{
					position: absolute;
				    left: 0;
				    bottom: 30px;
				    width: 100%;
				    margin: 0;
				    li{
				    	background-color: $white;
				    	height: 5px;
				    }
				}
			}
			figure{
				height: 400px;
				width: 100%;
				position: relative;
				margin-bottom: 30px;
				&:before{
					content:"";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: $green_rgba;
				}
			}
		}
		.activites_list_wrap{
			position: relative;
			margin-bottom: 40px;
			figure{
				height: 450px;
				width: 100%;
				opacity: 0.8;
				position: relative;
				@include transition;
				@media (max-width: $sm-width){
					height: 380px;
				}
				&:before{
					content:"";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: $green_rgba;
				}
			}
			&__text{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				padding:15px 15px 30px 15px	;
				h3{
					font-family: $poppinslight;
					@include font-size(24,32);
					color: $white;
					position: relative;
					letter-spacing: 0.4px;
					@media (max-width: $sm-width){
						@include font-size(20,26);
					}
					&:before{
						content:"";
						position: absolute;
						left: 0px;
						bottom: -4px;
						width: 30%;
						height: 2px;
						background: $white;
						@include transition;
					}
				}
			}
			> a{
				&:hover{
					figure{
						opacity:1;
					}
					.activites_list_wrap__text{
						h3{
							&:before{
								width:40%;
							}
						}
					}
				}
			}
		}
		.contact__information{
			@media (max-width: $md-width){
				margin-bottom: 40px;
			}
			address{
				font-family: $poppinsregular;
				@include font-size(16,27);
				color: $grey;
				max-width: 375px;
				margin-bottom: 30px;
			}
			ul{
				li{
					&:not(:last-child){
						margin-bottom: 10px;
					}
					a{
						font-family: $poppinsregular;
						@include font-size(17,27);
						color: $grey;
						display: block;
						padding-left: 37px;
						position: relative;
						i{
							font-size: 22px;
							position: absolute;
							left: 0;
							&:before{
								color:$green;
							}
						}
					}
				}
			}
		}
		.contact__form{
			.input_wrap{
				margin-bottom: 34px;
				input{
					width: 100%;
					font-family: $poppinslight;
					@include font-size(16,24);
					color: $grey;
					padding:7px 0;
					border-bottom:1px solid $grey;
					@include transition;
					@include placeholder{
						font-family: $poppinslight;
						@include font-size(16,24);
						color: $grey;
						@include transition;
					}
					&:focus{
						border-color: $green;
						@include placeholder{
							color:$green;
						}
					}
				}
			}
			.button_wrap{
				display: flex;
				justify-content: flex-end;
				button{
					font-family: $poppinsmedium;
					@include font-size(20,26);
					color: $white;
					background: $green;
					padding: 18px 15px;
					border-radius: 6px;
					min-width: 200px;
					text-align: center;
					i{
						margin-right: 8px;
						font-size: 26px;
						&:before{
							color:$white;
						}
					}
				}
			}
		}
	}
}
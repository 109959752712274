[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\e900";
}
.icon-direction:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-favicon .path1:before {
  content: "\e903";
  color: rgb(40, 108, 157);
}
.icon-favicon .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(12, 143, 169);
}
.icon-location:before {
  content: "\e905";
}
.icon-message:before {
  content: "\e906";
}
.icon-next:before {
  content: "\e907";
}
.icon-phone:before {
  content: "\e908";
}
.icon-send:before {
  content: "\e909";
}
.icon-twitter:before {
  content: "\e90a";
}
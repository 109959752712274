@font-face {
    font-family: 'Conv_Poppins-Bold';
    src: url('#{$fonts}/Poppins-Bold.eot');
    src: local('☺'), url('#{$fonts}/Poppins-Bold.woff') format('woff'), url('#{$fonts}/Poppins-Bold.ttf') format('truetype'), url('#{$fonts}/Poppins-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Poppins-Light';
    src: url('#{$fonts}/Poppins-Light.eot');
    src: local('☺'), url('#{$fonts}/Poppins-Light.woff') format('woff'), url('#{$fonts}/Poppins-Light.ttf') format('truetype'), url('#{$fonts}/Poppins-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Poppins-Medium';
    src: url('#{$fonts}/Poppins-Medium.eot');
    src: local('☺'), url('#{$fonts}/Poppins-Medium.woff') format('woff'), url('#{$fonts}/Poppins-Medium.ttf') format('truetype'), url('#{$fonts}/Poppins-Medium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Poppins-Regular';
    src: url('#{$fonts}/Poppins-Regular.eot');
    src: local('☺'), url('#{$fonts}/Poppins-Regular.woff') format('woff'), url('#{$fonts}/Poppins-Regular.ttf') format('truetype'), url('#{$fonts}/Poppins-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Poppins-SemiBold';
    src: url('#{$fonts}/Poppins-SemiBold.eot');
    src: local('☺'), url('#{$fonts}/Poppins-SemiBold.woff') format('woff'), url('#{$fonts}/Poppins-SemiBold.ttf') format('truetype'), url('#{$fonts}/Poppins-SemiBold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Blanka-Regular';
    src: url('#{$fonts}/Blanka-Regular.eot');
    src: local('☺'), url('#{$fonts}/Blanka-Regular.woff') format('woff'), url('#{$fonts}/Blanka-Regular.ttf') format('truetype'), url('#{$fonts}/Blanka-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'icomoon';
    src:  url('#{$fonts}/icomoon.eot?c2hgqw');
    src:  url('#{$fonts}/icomoon.eot?c2hgqw#iefix') format('embedded-opentype'),
        url('#{$fonts}/icomoon.ttf?c2hgqw') format('truetype'),
        url('#{$fonts}/icomoon.woff?c2hgqw') format('woff'),
        url('#{$fonts}/icomoon.svg?c2hgqw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
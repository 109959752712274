header{
	padding:51px 0 50px 0;
	@media (max-width: $md-width){
		padding:30px 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background: $white;
		z-index: 99;
		box-shadow: 0 0 8px $blue;
	}
	&.home-header{
		padding:74px 40px 0 40px;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 99;
		@media (max-width: $duble-width){
			padding:50px 15px 0 15px;
		}
		@media (max-width: $md-width){
			padding:20px 0;
			background:$white;
			position:fixed;
			z-index:9999;
		}
		nav{
			a{
				&.navbar-brand{
					margin:0;
					img{
						max-width: 140px !important;
						width: 100%;
						@include transition;
						@media (max-width: $md-width){
							max-width: 100px !important;
							position:absolute;
							z-index: 99;
						}
					}
				}
			}
			&.menuShow{
				a{
					&.navbar-brand{
						img{
							@media (max-width: $md-width){
								max-width:40px !important;
							}
						}
					}
				}
			}
		}
		.navbar-nav{
			background: $white;
		}
	}
	
	nav{
		padding:0 !important;
		align-items: flex-start !important;
		a{
			&.navbar-brand{
				margin-top: 10px;
				@media (max-width: $md-width){
					margin-top: 0;
				}
				img{
					max-width: 191px;
					@media (max-width: $md-width){
						max-width: 130px;
					}
				}
			}
		}
		.mobile-speed-menu{
			display: none;
			@media (max-width: $md-width){
				display: flex;
				flex-direction: row;
			}
			li{
				&:not(:last-child){
					padding:0 7px;
				}
				&:last-child{
					padding-left:7px;
				}
				&.location{
					a{
						background: $green;
					}
				}
				&.phone{
					a{
						background: $blue;
					}
				}
				a{
					display: block;
					padding:10px;
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 35px;
					i{
						font-size: 18px;
						&:before{
							color:$white;
						}
					}
				}
			}
		}
		button{
			&.navbar-toggler{
				padding: 0;
				margin:0;
				height: 32px;
				width: 35px;
				span.navbar-toggler-icon{
					width: 35px;
				    height: 2.5px;
				    border-radius: 2.5px;
				    background: $blue;
				    position: relative;
				    &:before,&:after{
				    	content:"";
				    	width: 35px;
					    height: 2.5px;
					    border-radius: 2.5px;
					    background: $blue;
					    position: absolute;
					    display: inline-block;
					    left: 0;
				    }
				    &:before{
				    	top: -10px;
				    }
				    &:after{
				    	bottom: -10px;
				    }
				}
			}
		}
		.collapse{
			justify-content: flex-end;
		}
		.navbar-nav{
			padding:27px 31px;
			@media (max-width: $md-width){
				padding:30px 0 0 0;
			}
			li{
				padding:0 12px;
				@media (max-width: $md-width){
					padding:0;
				}
				a{
					font-family: $poppinsmedium;
					@include font-size(15,21);
					color: $green;
					position: relative;
					@media (max-width: $md-width){
						padding:5px 0 !important;
					}
					&:before{
						content:"";
						position: absolute;
						left: 0;
						bottom: -2px;
						height: 2px;
						background: $green;
						width: 0;
						@include transition;
						@media (max-width: $md-width){
							bottom:3px;
						}
					}
					&:hover{
						&:before{
							width:40%;
							@media (max-width: $md-width){
								width:10%;
							}
						}
					}
				}
				&.active{
					a{
						&:before{
							width:40%;
							@media (max-width: $md-width){
								width:10%;
							}
						}
					}
				}
			}
		}
		.nav-icons{
			display: flex;
			flex-direction: row;
			padding-left: 13px;
			@media (max-width: $md-width){
				display: none;
			}
			li{
				&:not(:last-child){
					padding-right: 6px;
				}
				&.location{
					a{
						background: $green;
					}
				}
				&.phone{
					a{
						background: $blue;
					}
				}
				a{
					display: block;
					padding:10px;
					height: 75px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 75px;
					i{
						font-size: 33px;
						&:before{
							color:$white;
						}
					}
				}
			}
		}
	}
}
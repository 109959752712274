//......................................
//= PATHS
//......................................

$images 				:	'../images';
$fonts					:	'../fonts';

$poppinsbold			:	'Conv_Poppins-Bold';
$poppinsmedium			:	'Conv_Poppins-Medium';
$poppinssemibold		:	'Conv_Poppins-SemiBold';
$poppinsregular			:	'Conv_Poppins-Regular';
$poppinslight			:	'Conv_Poppins-Light';
$blankaregular			:	'Conv_Blanka-Regular';

//COLORS
$green				:	#0b8fa9;
$green_rgba			:	rgba(68,169,183,0.2);
$lightgreen			:	#24b5d2;
$blue				:	#4c86ae;
$blue_rgba			:	rgba(40,108,157,0.6);		
$black				:	#000;
$white				:	#fff;
$fontgrey			:	#2e2e2e;
$darkgrey			:	#333333;
$grey				:	#434343;

//BREAKPOINTS
$duble-width			: 1700px;
$max-xl-width			: 1400px;
$max-lg-width			: 1276px;
$lg-width				: 1200px;
$md-width				: 991px;
$sm-width				: 767px;
$xs-width				: 576px;

$mobile-width			: 375px;
footer{
	background: $darkgrey;
	position: relative;
	@media (max-width: $md-width){
		padding-bottom: 25px;
	}
	&:before{
		content:"";
		position: absolute;
		right: 0;
		width: 40%;
		background: $green;
		height: 100%;
		@media (max-width: $md-width){
			width: 100%;
			height: 80px;
			bottom: 0;
		}
	}
	.row{
		display: flex;
		align-items: center;
	}
	.footer__logo{
		padding:27px 0;
		@media (max-width: $md-width){
			text-align: center;
			padding:25px 0 15px 0;
		}
		img{
			max-width: 109px;
			width: 100%;
		}
	}
	.footer__menu{
		padding:10px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		ul{
			display: flex;
			align-items: center;
			justify-content: center;
			@media (max-width: $md-width){
				flex-direction: column;
			}
			li{
				padding: 0 15px;
				@media (max-width: $lg-width){
					padding:0 6px;
				}
				@media (max-width: $md-width){
					padding:5px;
				}
				a{
					font-family: $poppinsregular;
					@include font-size(14,21);
					color: $white;
				}
			}
		}
	}
	.footer__follow{
		padding:27px 0;
		background: $grey;
		@media (max-width: $md-width){
			background: none;
			padding:10px 0 30px 0;
		}
		ul{
			display: flex;
			align-items: center;
			justify-content: center;
			li{
				padding:0 3.5px;
				a{
					i{
						font-size: 26px;
						&:before{
							color:$white;
						}
					}
				}
				p{
					font-family: $poppinslight;
					@include font-size(12,18);
					color: $white;
					span{
						font-family: $poppinsbold;
						display: block;
					}
				}
			}
		}
	}
	.footer__gigant{
		display: flex;
		justify-content: flex-end;
		@media (max-width: $md-width){
			justify-content: center;
		}
		a{
			i{
				width: 90px;
				height: 37px;
				background: url(#{$images}/svg/gigant-logo-white.svg) no-repeat center;
				background-size: cover;
				display: inline-block;
			}
		}
	}
}